/* This example requires Tailwind CSS v2.0+ */
const features = [
    { name: 'Machinery Repairs', description: 'Designed by Good Goods, Inc.' },
    { name: 'Machinery Restorations', description: 'Solid walnut base with rare earth magnets and powder coated steel card cover' },
    { name: 'Trailer Repairs', description: '6.25" x 3.55" x 1.15"' },
    { name: 'Truck Body Repairs', description: 'Hand sanded and finished with natural oil' },
    { name: 'Earthmoving', description: 'Wood card tray and 3 refill packs' },
    { name: 'Bucket Repairs', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Fabrication', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Agricultural', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Machinery Repairs', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Jig Fabrication', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Custom one-off Jobs', description: 'Made from natural materials. Grain and color vary with each item.' },
    { name: 'Tig/Mig Welding Fitting and Turning', description: 'Made from natural materials. Grain and color vary with each item.' },
]

export default function WhatWeDo() {
    return (
        <div className="bg-white">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Services</h2>
                    <p className="mt-4 text-gray-500">
                        Our family business is really good and stuff and we do pretty much everyything....blah blah
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
                    <img
                        src="https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1589922585618-dfd1fcd87c28?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80g"
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1530124566582-a618bc2615dc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        className="rounded-lg bg-gray-100"
                    />
                    <img
                        src="https://images.unsplash.com/photo-1621905251189-08b45d6a269e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2969&q=80"
                        className="rounded-lg bg-gray-100"
                    />
                </div>
            </div>
        </div>
    )
}

import './App.css';
import HeroSection from "./HeroSection";
import WhatWeDo from "./WhatWeDo";
import FooterView from "./Footer";

function App() {
  return (
    <div className="App">
      <HeroSection/>
        <WhatWeDo/>
        <FooterView/>
    </div>
  );
}

export default App;
